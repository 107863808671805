import { useEffect } from 'react'
import styled from 'styled-components'

import { TilHRAd } from './til-hr-ad'
import { Button, Modal, ModalProps } from 'components/generic'
import { BigCheckmarkIcon, ErrorIcon, SpinnerIcon } from 'components/icons'

import { ModalTypeFactory, ModalComponentProps } from 'modal'
import { ChartDetail } from 'tree/types'
import { getIsSubscribed } from 'features/premium'
import { spaces } from 'theme'

type ProgressStatus = 'inProgress' | 'completed' | 'failed'
type Props = ModalComponentProps & {
  chartData: ChartDetail
  status?: ProgressStatus
  exportAgain?: () => void
}
export type ExportingModalType = ModalTypeFactory<'exportingModal', Props>

const STATUSES = {
  inProgress: { title: 'Exporting data', desc: 'Your Org Chart is being exported...' },
  completed: { title: 'Successfully exported', desc: 'Please wait for download.' },
  failed: { title: 'Failed to export, please try again', desc: 'If the problem persists, please contact us.' },
}

export const ExportingModal = ({ chartData, status = 'inProgress', onClose, exportAgain }: Props) => {
  const { title, desc } = STATUSES[status]
  const isNotInProgress = status !== 'inProgress'
  const displayButton = status === 'failed' && exportAgain

  const isSubscribed = getIsSubscribed(chartData.subscription)

  useEffect(() => {
    if (status === 'completed') {
      const timer = setTimeout(onClose, 10000)
      return () => clearTimeout(timer)
    }
  }, [status])

  return (
    <StyledModal
      size='small'
      closeButton={isNotInProgress}
      closeButtonProps={{ type: isSubscribed ? 'primary' : 'dark' }}
      onClose={() => (isNotInProgress ? onClose() : undefined)}
    >
      {!isSubscribed && <StyledTilHRAd chartUuid={chartData.id} />}

      <Container>
        {getIcon(status)}
        <Title>{title}</Title>
        <Description>{desc}</Description>

        {displayButton && (
          <Button spacing={{ mt: spaces.l }} onClick={exportAgain}>
            Try again
          </Button>
        )}
      </Container>
    </StyledModal>
  )
}

const getIcon = (status: ProgressStatus) => {
  if (status === 'inProgress') return <StyledSpinnerIcon />
  if (status === 'failed') return <StyledErrorIcon />
  return (
    <CheckmarkContainer>
      <StyledBigCheckmarkIcon />
    </CheckmarkContainer>
  )
}

const StyledModal = styled((props: ModalProps) => <Modal {...props} />)`
  && {
    padding: 0;
    overflow: visible;
  }
`

const Container = styled.div`
  min-height: 198px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.dark};
`

const CheckmarkContainer = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.green};
  animation: ${props => props.theme.keyframes.breatheIn} 1s ease-in-out;
`

const StyledBigCheckmarkIcon = styled(BigCheckmarkIcon)`
  width: 70%;
  height: auto;
  color: ${props => props.theme.colors.white};
`

const StyledErrorIcon = styled(ErrorIcon)`
  width: 38px;
  height: auto;
  color: ${props => props.theme.colors.redLighter};
  animation: ${props => props.theme.keyframes.breatheIn} 1s ease-in-out;
`

const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 38px;
  height: auto;
`

const Title = styled.div`
  margin-top: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.grey};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

const Description = styled.div`
  margin-top: ${props => props.theme.spaces.m};
  font-size: 13px;
  line-height: 16px;
`

const StyledTilHRAd = styled(TilHRAd)`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`
