import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AuthLayout } from 'components/layouts'
import { Loading } from 'components/generic'

import analytics from 'analytics'
import { CHARTS_PATH, LOGIN_PATH, LOGOUT_CALLBACK_PATH } from 'routes'
import { requestedUrlStorage, useAuth } from 'auth'
import { ORG_TRACK } from 'analytics/constants'

export const AuthCallbackScreen = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { isAuthenticated, user } = useAuth()

  useEffect(() => {
    isAuthenticated ? handleIsAuthenticated() : handleIsNotAuthenticated()
  }, [])

  const redirectCallback = useCallback((requestedUrl: string) => history.replace(requestedUrl), [history])

  const handleIsNotAuthenticated = () => {
    if (pathname === LOGOUT_CALLBACK_PATH) {
      analytics.track(ORG_TRACK.logout)
      requestedUrlStorage.removeItem()
    }
    redirectCallback(LOGIN_PATH)
  }

  const handleIsAuthenticated = () => {
    if (user) {
      const { name, email, createdAt } = user
      analytics.init(name, email, createdAt)
      analytics.track(ORG_TRACK.login)
      sessionStorage.removeItem('loginAttempts')
    }
    const requestedUrl = requestedUrlStorage.getItem()
    const redirectUrl = !requestedUrl || requestedUrl === LOGIN_PATH ? CHARTS_PATH : requestedUrl
    redirectCallback(redirectUrl)
  }

  return (
    <AuthLayout>
      <Loading size='big' />
    </AuthLayout>
  )
}
