import { useEffect, useState } from 'react'
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import styled from 'styled-components'

import { Link } from 'components/generic/link'
import { Button } from 'components/generic/button'
import { TilModalType } from 'components/specific'
import { EmailIcon, IntercomCloseIcon, IntercomIcon } from 'components/icons'

import { useModal } from 'modal/hooks'
import { CUSTOM_INTERCOM_LAUNCHER_ID, getIsCustomInitialized } from 'analytics/intercom'
import { useAuth } from 'auth'
import { useIntercom } from 'providers'
import { getIsSubscribed } from 'features/premium'

type DragItem = {
  id: string
  bottom: number
  right: number
}

const LAUNCHER_INIT: DragItem = {
  id: CUSTOM_INTERCOM_LAUNCHER_ID,
  right: 20,
  bottom: 20,
}

export const IntercomLauncher = () => {
  const [{ id, right, bottom }, setLauncher] = useState(LAUNCHER_INIT)
  const { showLauncher } = useIntercom()

  const [, drop] = useDrop(
    () => ({
      accept: CUSTOM_INTERCOM_LAUNCHER_ID,
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord
        const right = Math.round(item.right + delta.x * -1)
        const bottom = Math.round(item.bottom + delta.y * -1)
        setLauncher({ id: item.id, right, bottom })
      },
    }),
    [setLauncher]
  )

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: CUSTOM_INTERCOM_LAUNCHER_ID,
      item: { id, right, bottom },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, right, bottom]
  )

  const { user } = useAuth()
  const isSubscribed = getIsSubscribed(user.subscription)
  const [isOpened, setIsOpened] = useState(false)
  const [notificationNumber, setNotificationNumber] = useState(0)
  const [isIntercomInitialized, setIsIntercomInitialized] = useState(getIsCustomInitialized())
  const isIntercomAllowed = Boolean(isIntercomInitialized && isSubscribed)

  // Listen for Intercom initialization change
  useEffect(() => {
    const isIntercomInitialized = getIsCustomInitialized()
    const handler = () => setIsIntercomInitialized(true)
    isIntercomInitialized ? handler() : window.addEventListener('onIntercomInitialized', handler)
    return () => {
      window.removeEventListener('onIntercomInitialized', handler)
    }
  }, [])

  // Set Intercom listeners for custom launcher if allowed
  useEffect(() => {
    if (!isIntercomAllowed) {
      return
    }
    const windowObj = window as any
    windowObj?.Intercom('onHide', () => setIsOpened(false))
    windowObj?.Intercom('onShow', () => setIsOpened(true))
    windowObj?.Intercom('onUnreadCountChange', (unreadCount?: any) => {
      if (typeof unreadCount === 'number') setNotificationNumber(unreadCount)
    })
  }, [isIntercomAllowed, setIsOpened, setNotificationNumber])

  // Fallback modal if Intercom is not allowed
  const { open, close } = useModal()
  const handleOpenModal = () => {
    open<TilModalType>('tilModal', {
      title: 'Contact us',
      content: (
        <div>
          If you have any question or request, please contact us at:
          <br />
          <br />
          <Link href='mailto:info@timeisltd.com'>info@timeisltd.com</Link>
        </div>
      ),
      actionsArea: (
        <Button spacing={{ ml: 'auto' }} onClick={() => close<TilModalType>('tilModal')}>
          Ok
        </Button>
      ),
    })
  }

  return (
    <>
      <DropZone $isDragging={isDragging} ref={drop} />

      <Launcher
        onClick={isIntercomAllowed ? undefined : handleOpenModal}
        id={isIntercomAllowed ? CUSTOM_INTERCOM_LAUNCHER_ID : undefined}
        $right={right}
        $bottom={bottom}
        $isOpened={isOpened}
        $isHidden={!showLauncher}
        ref={isOpened ? undefined : drag}
      >
        {Boolean(notificationNumber) && <NotificationBadge>{notificationNumber}</NotificationBadge>}
        {isIntercomAllowed ? (
          isOpened ? (
            <IntercomCloseIcon />
          ) : (
            <IntercomIcon />
          )
        ) : (
          <EmailIcon width='100%' height='20px' />
        )}
      </Launcher>
    </>
  )
}

const DropZone = styled.div<{ $isDragging: boolean }>`
  position: fixed;
  bottom: 0;
  min-width: 100vw;
  width: 100%;
  min-height: calc(100vh - ${props => props.theme.sizes.topNavigation});
  height: calc(100% - ${props => props.theme.sizes.topNavigation});
  pointer-events: ${props => (props.$isDragging ? 'auto' : 'none')};
  visibility: ${props => (props.$isDragging ? 'visible' : 'hidden')};
  overflow: hidden;
  z-index: 9999;
`

const Launcher = styled.div<{ $right: number; $bottom: number; $isOpened: boolean; $isHidden: boolean }>`
  position: fixed;
  right: ${props => (props.$isOpened ? `${LAUNCHER_INIT.right}px` : `${props.$right}px`)};
  bottom: ${props => (props.$isOpened ? `${LAUNCHER_INIT.bottom}px` : `${props.$bottom}px`)};
  width: 60px;
  height: 60px;
  display: ${props => (props.$isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.purple};
  box-shadow: rgb(0 0 0 / 6%) 0px 1px 6px 0px, rgb(0 0 0 / 16%) 0px 2px 32px 0px;
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  text-align: center;
  font-size: 7px;
  cursor: pointer;
  z-index: 190;
`

const NotificationBadge = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgb(210, 38, 40);
  color: rgb(255, 255, 255);
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
`
