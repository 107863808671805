import { useEffect, useState } from 'react'

export const useOnConsentChanged = () => {
  const [changedAt, setChangedAt] = useState(Date.now())

  useEffect(() => {
    const id = setInterval(() => {
      const oneTrust = (window as any)?.OneTrust
      if (oneTrust) {
        oneTrust?.OnConsentChanged(() => {
          setTimeout(() => setChangedAt(Date.now()), 0) // next tick
        })
        clearInterval(id)
      }
    }, 1000)

    return () => id && clearInterval(id)
  }, [])

  return changedAt
}
