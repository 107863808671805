import { KeyboardEvent } from 'react'
import Select, { InputActionMeta, MultiValue } from 'react-select'
import { ErrorMessage } from 'formik'

import { FieldContainer, FieldErrorMessage, FieldTitle } from './components'
import { selectComponents, selectStyles } from '../select'

import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { getIsSubscribed } from 'features/premium'

export const validateDomain = (unserializedDomain: string, domains: string[] | readonly string[]) => {
  const domain = unserializedDomain.normalize().toLocaleLowerCase()

  const getError = (error: string, includeExample?: boolean) => {
    return `Domain "${domain}" ${error}.${includeExample ? ' Valid domain example: "timeisltd.com"' : ''}`
  }

  const isIncluded = domains.includes(domain)
  if (isIncluded) return getError('is already allowed')

  const hasAtSign = domain.includes('@')
  if (hasAtSign) return getError(`can't have "@" symbol`, true)

  const MIN_LENGTH = 3
  if (domain.length < MIN_LENGTH) return getError('is too short')

  const hasDot = domain.includes('.')
  if (!hasDot) return getError('is missing a dot', true)
}

type Props = {
  name: string
  domains: string[] | readonly string[]
  inputValue: string
  onDomainsChange: (domains: MultiValue<string>) => void
  onInputChange: (value: string) => void
  onError: (error: string) => void
}

export const FieldPublicDomainRestrict = ({
  name,
  domains,
  inputValue,
  onDomainsChange,
  onInputChange,
  onError,
}: Props) => {
  const { canUpdatePublicLink } = useChartPermission()
  const title = canUpdatePublicLink
    ? 'Allow access only to the following domains'
    : 'Allowed access only to the following domains'
  const placeholder = canUpdatePublicLink ? 'Use space or comma (,) to add multiple domains' : 'All domains allowed'

  const addDomain = (unserializedDomain: string) => {
    const domain = unserializedDomain.normalize().toLocaleLowerCase()

    const error = validateDomain(unserializedDomain, domains)
    if (error) return onError(error)

    onInputChange('')
    onDomainsChange([...domains, domain])
  }

  const { subscription } = useChartData()
  const isSubscribed = getIsSubscribed(subscription)
  const handleInputChange = (v: string, { action }: InputActionMeta) => {
    // Prevent input value change from other actions (like blur)
    if (action !== 'input-change') {
      return
    }
    // Prevent adding domains for unsubscribed users
    if (!isSubscribed) {
      onError('Only PRO users are allowed to add domains. Please upgrade to PRO.')
      return
    }

    const lastChar = v.slice(-1)
    const hasSpace = lastChar === ' '
    const hasComma = lastChar === ','

    if (hasSpace || hasComma) {
      const domain = v.split(hasSpace ? ' ' : ',')[0]
      addDomain(domain)
    } else {
      onInputChange(v)
    }
  }

  const handleBlur = () => {
    if (inputValue) addDomain(inputValue)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const hitEnter = e.code === 'Enter'
    if (hitEnter && inputValue) addDomain(inputValue)
  }

  return (
    <FieldContainer>
      <FieldTitle title={title} size='big' />
      <Select<string, true>
        value={domains}
        inputValue={inputValue}
        placeholder={placeholder}
        styles={selectStyles}
        menuIsOpen={false}
        getOptionLabel={domain => domain}
        getOptionValue={domain => domain}
        isDisabled={!canUpdatePublicLink}
        isMulti
        isClearable
        onInputChange={handleInputChange}
        onChange={v => onDomainsChange(v)} // Called only on domain deletion (adding is controlled outside select)
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        components={{
          Control: selectComponents.Control,
          ClearIndicator: selectComponents.ClearIndicator,
          Placeholder: selectComponents.Placeholder,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
      />
      <ErrorMessage name={name}>{message => (message ? <FieldErrorMessage message={message} /> : null)}</ErrorMessage>
    </FieldContainer>
  )
}
