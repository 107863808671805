import styled from 'styled-components'

import { NodeContent, NodeDetailItem } from '../../node-components'
import { DisplaySupervisorAndDepartment } from 'components/specific/display-supervisor-and-department'
import { ProfileInSquareIcon } from 'components/icons'

import { findSuperiorPerson, findSuperiorDepartment } from 'helpers'

import { DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  department: DepartmentDetailDataFragmentFragment
}

export const DepartmentDetails = ({ department }: Props) => {
  const { unassigned, description } = department
  const superiorPerson = findSuperiorPerson(department)
  const superiorDepartment = findSuperiorDepartment(department)

  return (
    <NodeContent>
      <InfoItem>
        <IconWrap>
          <ProfileInSquareIcon />
        </IconWrap>
        <DisplaySupervisorAndDepartment
          unassigned={unassigned}
          superiorPerson={superiorPerson}
          superiorDepartment={superiorDepartment}
        />
      </InfoItem>

      {description && <NodeDetailItem title='Description' value={description} />}
    </NodeContent>
  )
}

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 16px;
`

const IconWrap = styled.div`
  min-width: 16px;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  color: ${props => props.theme.colors.greyLight};
`
