import { SubscriptionStatus } from '../types'

export type GetSubscriptionStatusParams = {
  isOverusing: boolean
  isSubscribed: boolean
}

export const getSubscriptionStatus = ({
  isOverusing,
  isSubscribed,
}: GetSubscriptionStatusParams): SubscriptionStatus => {
  if (isSubscribed) {
    return 'premium'
  }
  if (isOverusing) {
    return 'freeOveruse'
  }
  return 'free'
}
