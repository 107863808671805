import { useEffect, lazy, Suspense } from 'react'
import { ToastContainer } from 'react-toastify'

import { IntercomLauncher } from './IntercomLauncher'
import { GlobalProviders } from './GlobalProviders'
import { GlobalStyleReset } from './GlobalStyleReset'
import { Routes } from './Routes'

import analytics from 'analytics'
import { useAuth } from 'auth'
import { useInitAppVersionCheck, useOnConsentChanged } from 'hooks'

import 'react-toastify/dist/ReactToastify.css'

const ModalRenderer = lazy(() => import('modal/components/modal-renderer'))
const GlobalModalRegisters = lazy(() => import('./GlobalModalRegisters'))

export const ConfigureApp = () => {
  const { user, isAuthenticated } = useAuth()
  const { name, email, createdAt, subscription } = user
  const changedAt = useOnConsentChanged()

  // Init Analytics on Cookie consent change or user log in
  useEffect(() => {
    analytics.init(name, email, createdAt)
    analytics.setUserProperties({ subscription: subscription?.plan || 'free' })
  }, [changedAt, isAuthenticated, name, email, createdAt])

  useInitAppVersionCheck()

  // No console logs, warnings or errors on production
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log = console.warn = console.error = () => {}
  }

  return null
}

export const App = () => (
  <GlobalProviders>
    <ToastContainer position='bottom-right' />
    <ConfigureApp />
    <GlobalStyleReset />
    <Suspense fallback={null}>
      <ModalRenderer />
      <GlobalModalRegisters />
    </Suspense>
    <Routes />
    <IntercomLauncher />
  </GlobalProviders>
)
