import { AnchorHTMLAttributes, ReactNode, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import { LinkStyles } from './link-styles'
import { applySpacingProps, SpacingProps } from 'helpers'

type Props = {
  children: ReactNode
  href?: string
  isInline?: boolean
  spacing?: SpacingProps
  as?: keyof JSX.IntrinsicElements
  onClick?: (e: SyntheticEvent) => void
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const Link = ({ as, target = '_blank', rel, isInline, spacing, children, ...rest }: Props) => {
  const customTarget = as?.toLocaleLowerCase() === 'a' || as === undefined ? target : undefined
  // Prevent usage of unsafe target='_blank' -> https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
  const safeRel = rel ?? target.toLocaleLowerCase() === '_blank' ? 'noreferrer' : ''
  return (
    <Anchor as={as} target={customTarget} rel={safeRel} $isInline={isInline} $spacing={spacing} {...rest}>
      {children}
    </Anchor>
  )
}

const Anchor = styled.a<{ $isInline?: boolean; $spacing?: SpacingProps }>`
  ${LinkStyles}

  ${props =>
    props.$isInline &&
    css`
      display: inline;
    `}

    ${props => applySpacingProps(props.$spacing)}
`
