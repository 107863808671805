import styled from 'styled-components'

import { TilButton } from 'components/generic'
import { TimeIsLtdLogo } from 'components/icons'
import SociomapUri from 'assets/sociomap-ad.png'

import analytics from 'analytics'
import { ADS_TRACK } from 'analytics/constants'
import { spaces } from 'theme'
import { TIL_HR_URL } from 'consts'

type Props = {
  chartUuid: string
  className?: string
}

export const TilHRAd = ({ chartUuid, className }: Props) => (
  <Container className={className}>
    <Text>Understand your team dynamic and drive bottom-up change.</Text>
    <TilButton
      href={TIL_HR_URL}
      target='_blank'
      spacing={{ mb: spaces.l }}
      type='secondary'
      onClick={() => {
        analytics.track(ADS_TRACK.adTilPopup)
        analytics.track(ADS_TRACK.adClicked, {
          chartUuid,
          type: 'popup',
          source: 'chart-page',
        })
      }}
    >
      Explore <TimeIsLtdLogo $spacing={{ mx: spaces.s }} width='72px' height='100%' /> now
    </TilButton>
    <Sociomap src={SociomapUri} />
  </Container>
)

const Container = styled.div`
  min-height: 198px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  background: radial-gradient(61.94% 61.94% at 22.56% 10.39%, #3c0675 0%, #4d009d 100%);
  text-align: center;
  color: ${props => props.theme.colors.white};
`

const Text = styled.div`
  max-width: 320px;
  margin-bottom: ${props => props.theme.spaces.xl};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.white};
  cursor: default;
`

const Sociomap = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(40%, 34%);
  width: 200px;
  pointer-events: none;
`
