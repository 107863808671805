import { useHistory, useLocation } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import { toast } from 'react-toastify'

import { BillingPeriod, requestProStorage, SubscriptionPlan } from 'features/premium'
import { useAuth } from 'auth'
import { BASE_URL, CHARTS_PATH, LOGIN_PATH } from 'routes'
import { useUrlParams } from 'hooks'
import { addSearchParamsToURL } from 'helpers'
import globalConfig from 'config'

import { useCreateStripeCheckoutSessionMutation } from 'apollo/generated/graphql'

export type PremiumPayScreenLocationState =
  | undefined
  | {
      origin?: string
    }

export type PremiumPayScreenUrlParams = {
  billingPeriod: BillingPeriod
  plan: SubscriptionPlan
  coupon?: string
}

export const usePremiumScreenHandler = () => {
  const history = useHistory()
  const { pathname, search, state } = useLocation<PremiumPayScreenLocationState>()
  const { billingPeriod, plan, coupon } = useUrlParams<Partial<PremiumPayScreenUrlParams>>()

  const { isAuthenticated } = useAuth()
  const requestPro = requestProStorage.getItem()

  const goToAuth = () => {
    const getProPathWithSearch = `${pathname}${search}`
    history.push(LOGIN_PATH, { requestedUrl: getProPathWithSearch })
  }

  const goToStripeCheckout = (stripeUrl: string) => {
    window.location.replace(stripeUrl)
  }

  const goToPricingPage = () => {
    const { origin } = state || {}
    const { url } = addSearchParamsToURL(globalConfig.PRICING_PAGE_URI, {
      origin: BASE_URL,
      ...(origin && { origin }),
    })
    window.open(url, '_blank')
    history.goBack()
  }

  const catchStripeMutation = (error: ApolloError) => {
    console.error(error)
    requestProStorage.removeItem()
    const errorMessage =
      'We encountered an unexpected error while reaching to payment screen. We apologize for any inconvenience this may cause. If the issue persists, please contact us at info@timeisltd.com for further assistance.'
    toast(errorMessage, { type: 'error', autoClose: false })
    history.push(CHARTS_PATH)
  }

  const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation({
    variables: { billingPeriod, plan, coupon },
    update: (_, result = {}) => {
      requestProStorage.removeItem()
      const stripeUrl = result?.data?.createStripeCheckoutSession?.url
      if (stripeUrl) goToStripeCheckout(stripeUrl)
    },
  })

  const handlePremiumScreen = () => {
    if (billingPeriod || plan || requestPro) {
      return isAuthenticated ? createStripeCheckoutSession().catch(catchStripeMutation) : goToAuth()
    }

    goToPricingPage()
  }

  return { handlePremiumScreen }
}
