import styled from 'styled-components'
import { requestedUrlStorage, useAuth } from 'auth'
import { useHistory, useLocation } from 'react-router-dom'

import { OrgShowcase } from './org-showcase'
import { LoginSignupButtonsGroup } from 'components/specific'
import { Loading } from 'components/generic'
import { AuthLayout } from 'components/layouts'
import { OrgchartFullLogo } from 'components/icons'

import { WORKSPACE_GOOGLE_MARKETPLACE_ORG_CHART_URL, TIL_DPA_URL, TIL_TERMS_CONDITIONS_URL } from 'consts'
import { CALLBACK_PATH } from 'routes'

import GoogleWorkspaceMarketplaceLogo from 'assets/google-workspace-marketplace-logo.png'

export const AuthScreen = () => {
  const history = useHistory()
  const { pathname, state } = useLocation<{ requestedUrl?: string }>()
  const requestedUrl = state?.requestedUrl
  const { isLoading, isAuthenticated } = useAuth()

  if (isLoading) {
    return (
      <AuthLayout>
        <Loading size='big' />
      </AuthLayout>
    )
  }

  if (requestedUrl) {
    requestedUrlStorage.setItem(requestedUrl)
  }

  if (isAuthenticated) {
    requestedUrlStorage.setItem(pathname)
    history.replace({ pathname: CALLBACK_PATH })
  }

  return (
    <AuthLayout>
      <SectionsContainer>
        <HeroSection>
          <HeroSectionContent>
            <StyledOrgchartFullLogo />

            <LoginSignupButtonsGroup onWhiteBackground />

            <BottomWrap>
              <GsuiteWrap>
                <GSuiteText>Also available on</GSuiteText>
                <GSuiteLink href={WORKSPACE_GOOGLE_MARKETPLACE_ORG_CHART_URL} target='blank'>
                  <img src={GoogleWorkspaceMarketplaceLogo} />
                </GSuiteLink>
              </GsuiteWrap>
              <LegalsContainer>
                <LegalLink href={TIL_TERMS_CONDITIONS_URL} target='_blank'>
                  Terms and Conditions
                </LegalLink>
                <LegalLink href={TIL_DPA_URL} target='_blank'>
                  DPA
                </LegalLink>
              </LegalsContainer>
            </BottomWrap>
          </HeroSectionContent>
        </HeroSection>

        <OrgShowcase />
      </SectionsContainer>
    </AuthLayout>
  )
}

const SectionsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex-direction: row;
    align-items: stretch;
  }
`

const HeroSection = styled.div`
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 40px 20px 0;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.16);

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    position: fixed;
    flex: 1 1 auto;
    max-width: calc(100vw / 2.5);
    max-height: 100vh;
    padding: 0 80px 0;
    overflow-y: auto;
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.laptop}) {
    padding: 0 120px 0;
  }
`

const HeroSectionContent = styled.div`
  width: 100%;
  max-width: 327px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  cursor: default;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    max-width: 268px;
    min-height: 100vh;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 50px;
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.smallDesktop}) {
    max-width: 327px;
    padding-top: 170px;
  }
`

const StyledOrgchartFullLogo = styled(OrgchartFullLogo)`
  width: auto;
  min-height: 47px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  color: ${props => props.theme.colors.dark};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    margin-top: auto;
    margin-bottom: 60px;
  }
`

const GsuiteWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: ${props => props.theme.colors.greyDark};
  text-decoration: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    justify-content: flex-start;
  }
`

const GSuiteText = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 18px;
`

const GSuiteLink = styled.a`
  text-decoration: none;
`

const LegalsContainer = styled.div`
  width: 100%;
  max-width: 223px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

const LegalLink = styled.a`
  padding: 2px;
  margin-right: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.greyLight};
  font-size: 11px;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`

const BottomWrap = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallDesktop}) {
    margin-bottom: 40px;
  }
`
