import { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { generatePath, useRouteMatch } from 'react-router-dom'

import { Heading } from '../components'
import { Sociomap, SociomapScale } from '../components/sociomap'
import { InfoBox } from '../components/widgets'
import { datasetToSociomap } from '../models'
import { monthNames, PHASE_INTERVAL, sociomapLabels } from '../constants'
import { useInsights, useInsightsState } from '../hooks'

import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'
import { InfoBoxButtonGoogleCalendar } from 'components/specific'
import { ArrowsDistanceIcon, GraphBarIcon, PaletteIcon, PauseIcon, PlayIcon } from 'components/icons'
import { mod } from 'helpers'
import { useWindowSize } from 'hooks'
import { useResponsiveInfo } from 'providers'
import { mainTheme } from 'theme'
import { useGoogleWorkspace } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { INSIGHTS_SOCIOMAP_CONNECT_GW_PATH } from 'routes'

import { InsightDatasetSociomap } from 'apollo/generated/graphql'

export const Sociomaps = () => {
  const { actualDataIndex, startMonthIndex, askForCalendarPermission, isComputingInsights, setMonthsDisabled } =
    useInsightsState()

  const chartUuid = useChartId()

  const windowSize = useWindowSize()
  const { isSmallDevice, isLaptop } = useResponsiveInfo()
  const { width } = windowSize
  const sociomapSize = isLaptop ? 800 : isSmallDevice ? (width ?? 320) - 32 : 580

  const [isAnimating, setIsAnimating] = useState(false)
  const [iteration, setIteration] = useState(0)
  const [socAnimationIndex, setSocAnimationIndex] = useState(actualDataIndex)

  const [agreeWithDemoSociomap, setAgreeWithDemoSociomap] = useState(false)

  const { data, error, loading } = useInsights(chartUuid)

  const { authorize } = useGoogleWorkspace()
  const { params } = useRouteMatch<Record<string, string>>()

  const handleAgreeWithSocimap = () => {
    window.localStorage.setItem(`insights-sociomap-demo-agree-${chartUuid}`, 'true')
    setAgreeWithDemoSociomap(true)
  }

  useEffect(() => {
    const agreeSociomapJSON = window.localStorage.getItem(`insights-sociomap-demo-agree-${chartUuid}`)
    setAgreeWithDemoSociomap(agreeSociomapJSON && JSON.parse(agreeSociomapJSON))
    analytics.track(INSIGHTS_TRACK.sociomapVisited, { chartUuid })
  }, [])

  useEffect(() => {
    if (isAnimating && actualDataIndex > 0) {
      setSocAnimationIndex(0)

      const phaseInterval = setInterval(
        () => setSocAnimationIndex(socAnimationIndex => mod(socAnimationIndex + 1, actualDataIndex + 1)),
        PHASE_INTERVAL
      )
      const iterationInterval = setInterval(
        () => setIteration(iteration => iteration + 1),
        (actualDataIndex + 1) * PHASE_INTERVAL
      )
      setMonthsDisabled(true)

      return () => {
        clearInterval(phaseInterval)
        clearInterval(iterationInterval)
        setMonthsDisabled(false)
      }
    }
  }, [isAnimating])

  if (loading) return null

  if (error || !data) {
    return (
      <>
        <StyledHeading>Sociomaps</StyledHeading>
        <InfoBox type='error'>
          Something went wrong or your company doesn&apos;t have have Sociomaps. If you think you should see this page,
          contact us on: info@timeisltd.com
        </InfoBox>
      </>
    )
  }

  const actualSociomapData = data[isAnimating ? socAnimationIndex : actualDataIndex].datasets
  const { individTeamsSociomap } = actualSociomapData
  const isDemo = Boolean((individTeamsSociomap as unknown as InsightDatasetSociomap)?.demo)
  const noData = individTeamsSociomap.values.length === 0

  return (
    <>
      <StyledHeading>Sociomaps</StyledHeading>
      {(isDemo || askForCalendarPermission) && !isComputingInsights && (
        <InfoBox type='til'>
          {askForCalendarPermission
            ? 'Share your Google Calendar and unlock more features.'
            : 'Launching soon! See demo now.'}
          {askForCalendarPermission && (
            <>
              <InfoBoxButtonGoogleCalendar
                size='small'
                redirectPath={generatePath(INSIGHTS_SOCIOMAP_CONNECT_GW_PATH, params)}
                authorize={authorize}
                spacing={{ m: `0 ${mainTheme.spaces.l}` }}
                outlineIsOnDark
              />
            </>
          )}
        </InfoBox>
      )}
      <SociomapWidget socmapSize={sociomapSize}>
        <SociomapContainer socmapSize={sociomapSize}>
          <Sociomap
            data={datasetToSociomap(individTeamsSociomap)}
            isDemo={isDemo}
            setAgreeWithSociomap={handleAgreeWithSocimap}
            agreeWithDemoSociomap={agreeWithDemoSociomap || !isDemo}
            width={sociomapSize}
          />
          {agreeWithDemoSociomap && actualDataIndex > 0 && !noData && (
            <TimeLine baseWidth={sociomapSize}>
              <PlayButton
                onClick={() => {
                  setIsAnimating(!isAnimating)
                  analytics.track(INSIGHTS_TRACK.sociomapAnimationTouch, { chartUuid })
                }}
              >
                {isAnimating ? <TimelinePauseIcon /> : <TimelinePlayIcon />}
              </PlayButton>
              {[...Array(actualDataIndex + 1)].map((_, i) => (
                <Line key={`${i}-${iteration}`}>
                  <Progress progressOrder={i} linesCount={actualDataIndex + 1} isAnimating={isAnimating} />
                  <LineLabel>{monthNames[startMonthIndex + i]}</LineLabel>
                </Line>
              ))}
            </TimeLine>
          )}
        </SociomapContainer>
        <Info>
          <Title>{sociomapLabels.individTeamsSociomap.label}</Title>
          <SubTitle>How to read a sociomap?</SubTitle>
          <LineBreak />
          <SubTitle>
            <Icon>
              <PaletteIcon />
            </Icon>
            Height (color)
          </SubTitle>
          <Description>
            The color of a given person on the map indicates the total collaboration intensity for a given period.
          </Description>
          <SociomapScale labels={sociomapLabels.individTeamsSociomap.scale} spacing={{ mt: 10 }} />
          <LineBreak />
          <SubTitle>
            <Icon>
              <ArrowsDistanceIcon />
            </Icon>
            Position on the map
          </SubTitle>
          <Description>
            Mutual proximity represents how much the team members have been seeing each other over the last period.
            People who are close to each other have been collaborating intensively. People who are further apart have
            been collaborating less often. It doesn’t matter whether someone is placed at the top, bottom, middle,
            right, or left. The map is flat, only the mutual proximity is important.
          </Description>
          <LineBreak />
          <SubTitle>
            <Icon>
              <StyledGraphBarIcon />
            </Icon>
            Data
          </SubTitle>
          <Description>
            The Sociomap is based on your team&apos;s meetings taken from your company calendar.
          </Description>
        </Info>
      </SociomapWidget>
    </>
  )
}

const SociomapWidget = styled.div<{ socmapSize: number }>`
  display: flex;
  width: 100%;
  height: ${props => props.socmapSize}px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 6px rgba(38, 15, 63, 0.08);
  border-radius: 6px;
  max-width: 1400px;
  align-self: center;
  position: relative;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-direction: column;
    margin-bottom: calc(${props => props.theme.spaces.l} * 3);
    height: auto;
  }
`

const SociomapContainer = styled.div<{ socmapSize: number }>`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    display: flex;
    width: 100%;
    position: relative;
    height: ${props => props.socmapSize}px;
  }
`

const Info = styled.div`
  min-width: 200px;
  width: 100%;
  padding: 25px;
  flex-grow: 1;
  color: ${props => props.theme.colors.dark};
  background: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: calc(100vw - (${props => props.theme.spaces.l} * 3));
    padding: ${props => props.theme.spaces.m};
    padding-bottom: ${props => props.theme.spaces.l};
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 45px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin: ${props => props.theme.spaces.l} 0;
  }
`

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
`

const Description = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #83869c;
`

const Icon = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 10px;
  color: #4d009d;
`

const StyledGraphBarIcon = styled(GraphBarIcon)`
  transform-origin: 0 0;
  transform: scale(1.4);
`

const LineBreak = styled.hr`
  margin: 20px 0;
  border: none;
  border-top: 1px solid #e6e7ed;
`

const TimeLine = styled.div<{ baseWidth: number }>`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 100;
  width: calc(${props => props.baseWidth}px - 30px);
  height: 38px;
  bottom: 22px;
  padding: 0 15px;
`

const Line = styled.div`
  display: flex;
  position: relative;
  flex: auto;
  height: 5px;
  width: 30px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 1px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

const LineLabel = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 8px);
`

const PlayButton = styled.div`
  display: flex;
  position: absolute;
  z-index: 50;
  border-radius: 38px;
  width: 38px;
  height: 38px;
  background: ${props => props.theme.colors.white};
  border: 1px solid #e6e7ed;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    transition: opacity ease-in-out;
  }
  &:hover > svg {
    opacity: 0.7;
  }
`

const TimelinePlayIcon = styled(PlayIcon)`
  color: #4d009d;
`

const TimelinePauseIcon = styled(PauseIcon)`
  color: #4d009d;
`

const loaderKeyframes = keyframes`
    0% {
        width: 0;
    }
    50% {
        width: 50%;
    }
    100% {
        width: 100%;
    }
`

const Progress = styled(Line)<{ progressOrder: number; linesCount: number; isAnimating?: boolean }>`
  flex: initial;
  background: #4d009d;
  width: 0%;
  position: realive;
  margin: 0;

  ${props =>
    props.isAnimating &&
    css`
      animation: 3s ${loaderKeyframes} linear forwards;
      animation-delay: ${props.progressOrder * 3}s;
    `}
`

const StyledHeading = styled(Heading)`
  display: flex;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-bottom: ${props => props.theme.spaces.l};
  }
`
