import styled from 'styled-components'

import { Link } from 'components/generic'
import { getIsCustomInitialized, showNewMessage } from 'analytics/intercom'
import { capitalize } from 'helpers'

type Props = {
  intercomMsg?: string
  capFirstLetter?: boolean
}

export const ContactLink = ({ intercomMsg = '', capFirstLetter }: Props) => {
  const intercomText = ' Contact us via Intercom'
  const mailText = ' Email us at '

  return getIsCustomInitialized() ? (
    <Link as='div' onClick={() => showNewMessage(intercomMsg)}>
      {capFirstLetter ? capitalize(intercomText) : intercomText}
    </Link>
  ) : (
    <ContactTroughEmail>
      {capFirstLetter ? capitalize(mailText) : mailText}
      <Link href='mailto:info@timeisltd.com'>info@timeisltd.com</Link>
    </ContactTroughEmail>
  )
}

const ContactTroughEmail = styled.div`
  display: inline-block;
`
