import mixpanel from './mixpanel'
import intercom from './intercom'
import gtm from './gtm'
import hotjar from './hotjar'

import config from '../config'

import { clientAppVersion, getCookie } from 'helpers'
import { TrackKeyName } from 'types'

const {
  MIXPANEL_ENABLED,
  MIXPANEL_TOKEN,
  MIXPANEL_PROXY_URI,
  INTERCOM_ENABLED,
  INTERCOM_APP_ID,
  GTM_ENABLED,
  GTM_ID,
  HOTJAR_ENABLED,
  HOTJAR_ID,
  HOTJAR_SNIPPED_VERSION,
} = config

let cookiesEnabled = false

const getCookiesEnabled = () => {
  const optanonConsent = getCookie('OptanonConsent')
  const overrideCookieConsent = process.env.REACT_APP_COOKIES_ACCEPTED === 'true'
  return Boolean(optanonConsent?.includes('C0002%3A1') || optanonConsent?.includes('C0002:1')) || overrideCookieConsent
}

export const init = (name?: string, email?: string, createdAt?: Date) => {
  const isAuthenticated = Boolean(email?.length)
  if (GTM_ENABLED) gtm.init(GTM_ID)
  if (MIXPANEL_ENABLED && (isAuthenticated || cookiesEnabled)) {
    mixpanel.init(MIXPANEL_TOKEN, MIXPANEL_PROXY_URI)
    mixpanel.identify(name, email)
  }
  cookiesEnabled = getCookiesEnabled()
  if (!cookiesEnabled) {
    return
  }
  if (INTERCOM_ENABLED) intercom.init(INTERCOM_APP_ID, name, email, createdAt)
  if (HOTJAR_ENABLED) hotjar.init(HOTJAR_ID, HOTJAR_SNIPPED_VERSION)
}

export const setUserProperties = (properties: Record<string, any>) => {
  if (MIXPANEL_ENABLED) mixpanel.setUserProperties(properties)
  if (!cookiesEnabled) {
    return
  }
  if (INTERCOM_ENABLED) intercom.setUserProperties(properties)
}

export const track = (name: TrackKeyName, properties?: Record<string, any>) => {
  const newProperties = { ...properties, clientAppVersion }
  if (MIXPANEL_ENABLED) mixpanel.track(name, newProperties)
  if (!cookiesEnabled) {
    return
  }
  if (INTERCOM_ENABLED) intercom.track(name, newProperties)
  if (GTM_ENABLED) gtm.track(name, newProperties)
}

export default {
  init,
  setUserProperties,
  track,
}
