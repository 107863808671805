import { CalendarLink } from './calendar-link'
import { CustomFieldsDetail } from './custom-fields-detail'
import { GroupHeading } from '../../components'
import { NodeDetailItem } from '../../../node-components'
import { ProfileInfoCardIcon } from 'components/icons'

import { useChartSidePanelDetail } from 'tree/providers'
import { AboutFieldsOnDetail, CustomFieldValueFilledWithoutConnection, PersonDetail } from 'tree/types'

type Props = {
  person: PersonDetail
}

export const AboutFieldsDetail = ({ person }: Props) => {
  const { calendarUrl, employeeId, employeeType, customFieldsFilled } = person
  // const showGoogleCalendarPermissionRequest = providerInfo?.name === 'gsuite' && !hasScope('calendarReadonly') && userPermission?.role === 'admin'

  const customFieldsFilledWithoutConnection = customFieldsFilled.filter(
    cf => cf.type !== 'connection'
  ) as CustomFieldValueFilledWithoutConnection[]

  const aboutFields: AboutFieldsOnDetail = {
    calendarUrl,
    employeeId,
    employeeType,
    customFieldsFilled: customFieldsFilledWithoutConnection,
  }
  const showAboutFields = calendarUrl || employeeId || employeeType || customFieldsFilledWithoutConnection.length > 0

  if (!showAboutFields) {
    return null
  }

  return <AboutFieldsDetailInner aboutFields={aboutFields} />
}

type PropsInner = {
  aboutFields: AboutFieldsOnDetail
}

export const AboutFieldsDetailInner = ({ aboutFields }: PropsInner) => {
  const { calendarUrl, employeeId, employeeType, customFieldsFilled } = aboutFields
  const { isAboutExpanded, setIsAboutExpanded } = useChartSidePanelDetail()

  return (
    <section>
      <GroupHeading
        icon={<ProfileInfoCardIcon width='17px' />}
        title='About'
        isExpanded={isAboutExpanded}
        onClick={() => setIsAboutExpanded(!isAboutExpanded)}
      />

      {isAboutExpanded && (
        <>
          {calendarUrl && <CalendarLink url={calendarUrl} />}
          {/* The permission is not needed for now */}
          {/* {showGoogleCalendarPermissionRequest && <CalendarPermissionRequest />} */}
          {employeeType && <NodeDetailItem title='Employee type' value={employeeType} />}
          {employeeId && <NodeDetailItem title='Employee Id' value={employeeId} />}
          <CustomFieldsDetail customFields={customFieldsFilled} />
        </>
      )}
    </section>
  )
}
