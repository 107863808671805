import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { ChartCardCalendarAnalyticsBanner } from './chart-card-calendar-analytics-banner'
import { chartCardCSS } from './chart-card-css'

import { getSubscriptionStatus } from 'features/premium'
import { ContentfulTag, getChartsBannerAdEntries } from 'contentful-cms'
import { isValidUrl } from 'helpers'
import { TIL_URL } from 'consts'
import analytics from 'analytics'
import { ADS_TRACK } from 'analytics/constants'

type Props = {
  isSubscribed: boolean
  isOverusing: boolean
}

export const ChartCardBanner = ({ isSubscribed, isOverusing }: Props) => {
  const [imageSrc, setImageSrc] = useState('')
  const [href, setHref] = useState('')
  const [error, setError] = useState<unknown>()

  const tag: ContentfulTag = getSubscriptionStatus({ isOverusing, isSubscribed })

  useEffect(() => {
    getChartsBannerAdEntries(tag)
      ?.then(response => {
        const fields = response?.items?.[0]?.fields || {}
        const { bannerAdImage, redirectUrl } = fields

        if (bannerAdImage && 'fields' in bannerAdImage) {
          const newImageSrc = bannerAdImage.fields.file?.url
          if (typeof newImageSrc === 'string') setImageSrc(newImageSrc)
        }

        const newHref = isValidUrl(redirectUrl) ? redirectUrl : TIL_URL
        setHref(newHref)
      })
      .catch(setError)
  }, [tag])

  if (error || !imageSrc) {
    return <ChartCardCalendarAnalyticsBanner />
  }

  return (
    <ChartCardWrap
      onClick={() => {
        analytics.track(ADS_TRACK.adClicked, {
          type: 'Charts Banner Ad',
          source: 'overview',
          redirectUrl: href,
          tag,
        })
      }}
      href={href}
      target='_blank'
    >
      <Img src={imageSrc} />
    </ChartCardWrap>
  )
}

const ChartCardWrap = styled.a`
  ${chartCardCSS}
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50%;
`
