import { useMemo, useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import auth0React, { useAuth0 } from '@auth0/auth0-react'

import requestedUrlStorage from './requested-url.storage'
import { BASE_URL, LOGIN_PATH, LOGOUT_CALLBACK_PATH } from 'routes'
import { SubscriptionPlan } from 'features/premium'
import { ORG_CHART_TIL_URL } from 'consts'

import { SubscriptionInfoFragment, UserQuery, useUserQuery } from 'apollo/generated/graphql'

type RedirectLoginOptions = Omit<auth0React.RedirectLoginOptions, 'redirectUri'> & {
  redirectPath?: string
  connection?: 'google-oauth2'
  mode?: 'signUp' | 'login'
}

type LogoutOptions = Omit<auth0React.LogoutOptions, 'returnTo'> & {
  redirectPath?: string
}

type LoginOptions = {
  isSilentLogin?: boolean
  redirectPath?: string
}

type AuthProvider = 'auth0' | 'google-oauth2'

type User = {
  createdAt: Date | undefined
  name: string
  email: string
  isEmailVerified: boolean
  authProvider?: AuthProvider
  plan: SubscriptionPlan
  subscription?: SubscriptionInfoFragment
}

const handleRedirectPath = (redirectPath?: string) => {
  if (redirectPath) {
    requestedUrlStorage.setItem(redirectPath)
  }
}

export const useAuthActions = () => {
  const { replace = () => {} } = useHistory() ?? {}
  const { getAccessTokenSilently, loginWithRedirect: auth0LoginWithRedirect, logout: auth0Logout } = useAuth0<User>()

  const increaseLoginAttempt = useCallback(() => {
    const currentLoginAttempts = parseInt(sessionStorage.getItem('loginAttempts') ?? '0')
    sessionStorage.setItem('loginAttempts', (currentLoginAttempts + 1).toString())
  }, [])

  const loginWithRedirect = useCallback(
    (options: RedirectLoginOptions = {}) => {
      const { redirectPath, ...rest } = options

      increaseLoginAttempt()
      handleRedirectPath(redirectPath)

      auth0LoginWithRedirect({
        prompt: 'login',
        ...rest,
      })
    },
    [handleRedirectPath, auth0LoginWithRedirect]
  )

  const login = useCallback(
    (options: LoginOptions = {}) => {
      const { redirectPath, isSilentLogin } = options

      if (!isSilentLogin) increaseLoginAttempt()
      handleRedirectPath(redirectPath)

      replace(generatePath(LOGIN_PATH))
    },
    [handleRedirectPath, replace, generatePath]
  )

  const logout = useCallback(
    (options: LogoutOptions = {}) => {
      const returnTo = `${BASE_URL}${LOGOUT_CALLBACK_PATH}`
      const { redirectPath, ...rest } = options

      handleRedirectPath(redirectPath)

      auth0Logout({
        returnTo,
        ...rest,
      })
    },
    [handleRedirectPath, auth0Logout]
  )

  return {
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    login,
  }
}

export const useAuth = () => {
  const actions = useAuthActions()
  const { user: auth0User, isLoading: auth0Loading, ...auth0 } = useAuth0<User>()
  const { loading: userQueryLoading, data } = useUserQuery()
  const userData = data?.user
  const isLoading = auth0Loading || userQueryLoading

  const mapToUser = useCallback((user: auth0React.User = {}, userData: UserQuery['user']): User => {
    const createdAtString = user[`${ORG_CHART_TIL_URL}/created_at`]

    let createdAt: Date | undefined
    if (createdAtString) {
      const parsedCreatedAt = new Date(createdAtString)
      const createdAtTimestamp = parsedCreatedAt.getTime()
      if (!isNaN(createdAtTimestamp) && createdAtTimestamp > 0) {
        createdAt = parsedCreatedAt
      }
    }

    const name: string = userData?.name ?? ''
    const email: string = userData?.email ?? ''
    const isEmailVerified = Boolean(userData?.emailVerified)
    const authProvider = userData?.provider as AuthProvider
    const subscription = userData?.subscription

    return {
      createdAt,
      name,
      email,
      isEmailVerified,
      authProvider,
      plan: (subscription?.plan as SubscriptionPlan) || 'free',
      subscription: subscription && {
        ...subscription,
        plan: subscription?.plan as SubscriptionPlan,
      },
    }
  }, [])

  const user = useMemo(() => {
    return mapToUser(auth0User, userData)
  }, [mapToUser, userData, auth0User])

  return {
    ...auth0,
    ...actions,
    isLoading,
    user,
  }
}
