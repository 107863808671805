import { gql } from '@apollo/client'

export const publicLinkFragment = gql`
  fragment PublicLink on PublicLink {
    id
    uuid
    url
    domains
    allowedCustomFields
  }
`
